/**
 * Functions for navigation
 **/

var currentScrollPos = 0;
var menuOpeningScrollPos = 0;
var viewportWidth = window.innerWidth;

var openNav = (event) => {
  menuOpeningScrollPos = window.scrollY;
  var menu = document.querySelector('.main-menu');
  menu.classList.toggle('show');  
  menu.setAttribute('aria-hidden', 'false');
  document.addEventListener('keyup', handleEscape);
  document.querySelector('#close-menu').addEventListener('click', closeNav);
  document.body.classList.add('mobile-nav-open')
  document.body.style.overflow = 'hidden'
  document.querySelector('#main-menu').focus()
  event && event.stopPropagation();
}

var closeNav = (event) => {
  var isMobileMenuOpen = document.body.classList.contains('mobile-nav-open')
  var menu = document.querySelector('.main-menu');
  document.body.classList.remove('mobile-nav-open')
  document.body.style.overflow = ''
  menu.classList.remove('show');
  menu.setAttribute('aria-hidden', 'true');

  if (isMobileMenuOpen) {
    window.scrollTo({
      top: menuOpeningScrollPos,
      left: 0,
      behavior: "instant"
    });
  }

  document.removeEventListener('keyup', handleEscape);
  document.querySelector('#close-menu').removeEventListener('click', closeNav);

  if (!document.body.classList.contains('campaignpage')) {
    OAJ.menubuilder.closeCollapses();
  }

  event && event.stopPropagation();
}

var handleEscape = (event) => {
  var key = event.which || event.keyCode;
  if (key === 27 && closeNav()) {
    event.stopPropagation();
  }
};

var handleMenu = () => {
  var menu = document.querySelector('.main-menu');
  var menuHeight = document.querySelector('.site-header').scrollHeight;
  var contentWidth = document.querySelector('header').offsetWidth;
  document.documentElement.style.setProperty('--oaj-menu-height', menuHeight + 'px');
  document.documentElement.style.setProperty('--oaj-content-width', contentWidth + 'px');
  var ww = window.innerWidth;

  if (ww > 991.98) {
    menu.classList.contains('show') && closeNav();
    menu.getAttribute('aria-hidden') === 'true' && menu.setAttribute('aria-hidden', 'false');
    document.querySelector('.main-menu').setAttribute('aria-hidden', 'false');
  }

  if (ww !== viewportWidth) {
    closeNav()
    viewportWidth = ww
  }

  handleStickyMenu()
}

var handleStickyMenu = () => {
  var headerElement = document.querySelector('.site-header');
  var topnavElement = document.querySelector('.top-nav');
  var disppearingPoint = topnavElement.scrollHeight + headerElement.scrollHeight;
  var topnavHeight = topnavElement.scrollHeight;
  var scrollDirectionUp = currentScrollPos !== 0 && Math.round(currentScrollPos) > Math.round(window.scrollY);
  var scrollDirectionDown = currentScrollPos !== 0 && Math.round(currentScrollPos) < Math.round(window.scrollY);

  if (window.scrollY > disppearingPoint) {
    headerElement.style.transition = null;

    if (scrollDirectionUp) {
      headerElement.classList.add('sticky');
      headerElement.style.top = '0px';
    } else if (scrollDirectionDown) {
      headerElement.style.top = -menuHeight + 'px';
    }

  } else if (window.scrollY <= topnavHeight) {
    headerElement.style.transition = 'none';
    headerElement.classList.remove('sticky');
    headerElement.style.top = topnavHeight + 'px';
  }

  currentScrollPos = window.scrollY;
}

var menuHeight = document.querySelector('.site-header').scrollHeight;
var contentWidth = document.querySelector('header').offsetWidth;
document.documentElement.style.setProperty('--oaj-menu-height', menuHeight + 'px');
document.documentElement.style.setProperty('--oaj-content-width', contentWidth + 'px');


document.addEventListener('DOMContentLoaded', function (event) {
  window.innerWidth > 991.98 && document.querySelector('.main-menu').setAttribute('aria-hidden', 'false');
  document.querySelector('.nav-toggle').addEventListener('click', openNav);

  // To remove aria-hidden and update menu height var
  window.addEventListener('resize', handleMenu);
  currentScrollPos = window.scrollY;
  document.addEventListener('scroll', handleStickyMenu);
});

if (document.querySelector('.changeable-words')) {
  const time = document.querySelector('.changeable-words').getAttribute('data-oaj-interval') || 3000;
  const elements = document.querySelectorAll('.changeable-words span');
  const itemCount = elements.length;
  let index = 0;

  setInterval(function () {
    elements[index].classList.remove('active');
    elements[index].setAttribute('aria-hidden', 'true')
    index < itemCount - 1 ? index++ : index = 0;
    setTimeout(() => {
      elements[index].classList.add('active');
      elements[index].setAttribute('aria-hidden', 'false')
    }, 500);
  }, time);
}
/**
 * Functions to modify links
 **/

document.addEventListener('DOMContentLoaded', function () {

  document.querySelectorAll('.download-link').forEach(link => {
    link.setAttribute('download', '')
  })

  document.querySelectorAll('.external-link').forEach(link => {
    link.setAttribute('target', '_blank')
  })
});
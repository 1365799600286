document.addEventListener('DOMContentLoaded', function (event) {
  var loggedIn = document.getElementById('loggedInNotification')

  if (loggedIn) {

    if (window.sessionStorage.getItem('ulin') === '0') {
      loggedIn.innerHTML = `<div class="row py-5 py-md-8"><div class="col-12"><p class="text-md bold text-light mb-0">${window.loggedInNotification}</p></div></div>`
      loggedIn.classList.remove('d-none');
      window.sessionStorage.setItem('ulin', '1')
    } else {
      loggedIn.classList.add('d-none');
    }

  } else {
    window.sessionStorage.setItem('ulin', '0')
  }
});
var elements;

var animate = () => {

  var halfDisplay = window.innerHeight / 2
  elements.forEach(el => {
    var blockPos = el.getBoundingClientRect().top

    if (halfDisplay - blockPos > 0) {
      el.classList.add('show')
      var elCount = 0

      el.querySelectorAll('.animate-items > div > .block-padding').forEach(e => {
        elCount++
        setTimeout(() => {
          e.classList.add('show');
        }, 300 * elCount)
      })
    }
  })

  elements = document.querySelectorAll('.title-and-content-block.animate:not(.show)')

  if (elements.length === 0) {
    document.removeEventListener('scroll', animate)
  }
}

document.addEventListener('DOMContentLoaded', function () {
  elements = document.querySelectorAll('.title-and-content-block.animate:not(.show)');

  if (elements.length > 0) {
    document.addEventListener('scroll', animate)
  }
})